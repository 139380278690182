import * as React from "react"

import Layout from "../components/layout"
import Seo from "@components/Seo.js"

const Pdf = ({pageContext}) => {
    const {pathName, title} = pageContext
    const pageData = {
        title: title,
        description: `Looking for some inspiration for your school assignment? Get free essay samples on "${title}" from our team of professional academic writers at CustomEssayMeister.com!`,
        keywords: `essay, writers, custom, plagiarism free`
    }
    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <div className="pdf">
                    <iframe src={`/pdf/${pathName}.pdf`} width="100%" height="100%" />
                </div>
            </Layout>
        </>
    )
}

export default Pdf
